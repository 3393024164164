import React, { Component } from 'react';
import Parse from 'parse';
import $ from "jquery";
import './challenge.css';
import SaveNotification from "./saveNotification.jsx";
const options = [ 
    { value: 'Pending', label: 'Pending' }, 
    { value: 'Billed', label: 'Billed' }, 
    { value: 'Active', label: 'Active' },     
    { value: 'Complete', label: 'Complete'}, 
  ]; 
class EditChallenge extends Component {

  constructor(props) {
        super(props);
        Parse.initialize("ze4eQAdmycW7e8MCskILkfGFUNdwYnsKSsZDi0t5", "sDC1XmRXe4bP5i1VqhBhy8cIdiWrBpJmvtmmwvIw"); 
        //Parse.serverURL = "https://parse.joelskitchen.com/parse";
         Parse.serverURL = "https://parsetest.joelskitchen.com/parse";

        let currentUser = Parse.User.current();   
        
        if(!currentUser)
        {
            window.location.href = "/";
        }
        const queryString = window.location.href;
         var challenge_id = queryString.split("/")[4]; 
         this.checkChallengeAvailable(challenge_id);
         this.challenge_id=challenge_id;
        this.state = {
            parseFile_210:'',
            parseFile_640:'',
            challenge_title_msg:null,
            challenge_image_msg:null,
            challenge_caption_msg:null,
            more_creators:false,
            more_content:false,
            create_message:null,
            create_message_show:false,
            challenge_image_msg_size:false,
            job_number:null,
            challengeTitle:null, 
            caption:null, 
            more_creators_vals:false,
            more_content_vals:false,
            dataurl_640_img:null,
            dataurl_210_img:null,
            challengeStatus:null,
            checked: true,
            more_creators_valss:false,
            more_content_valss:false,
            challengeHashtag:null,
            businessAccount:null,
            prize:null,
            challengeAmount:null,
            startDate:null,
            endDate:null,
            totalCost:null,
            radiotype:null,
            more_content_valss1:null,
            barcolorvalue:null,
            makepayment:0,
            challenge_message:null,
            isPaid:0,
            payment_total_amount:null,
            payment_created_at:null,
            isNotificationOpen:false,
            post_Notification_Id:'',
            post_Notification_Msg:'',
            post_Notification_Comment:'',
            challengemainid:this.challenge_id

        };
         this.handleFileChange = this.handleFileChange.bind(this);
         this.discardImage = this.discardImage.bind(this);
         this.handleChallengeSubmit = this.handleChallengeSubmit.bind(this);
         this.handleMoreCreators = this.handleMoreCreators.bind(this);
         this.handleMoreContent = this.handleMoreContent.bind(this); 
         this.checkChallengeDiscard = this.checkChallengeDiscard.bind(this); 
         this.challengeCancel = this.challengeCancel.bind(this); 
         this.handleDeleteBtn = this.handleDeleteBtn.bind(this);
         this.handleStatusChange = this.handleStatusChange.bind(this);
         this.handleChallengeTitle = this.handleChallengeTitle.bind(this);         
         //this.handleHashtag = this.handleHashtag.bind(this);
         this.handleCaption = this.handleCaption.bind(this);
         this.checkCancelBtn = this.checkCancelBtn.bind(this);
         this.handleChallengejobNumber = this.handleChallengejobNumber.bind(this);
         this.handleChallengeBusinessAccount = this.handleChallengeBusinessAccount.bind(this);
         this.handleChallengePrize = this.handleChallengePrize.bind(this);
         this.handleChallengeAmount = this.handleChallengeAmount.bind(this);
         this.handleStartDate = this.handleStartDate.bind(this);
         this.handleEndDate = this.handleEndDate.bind(this);
         //this.handleTotalCost = this.handleTotalCost.bind(this);
         this.paymentclick = this.paymentclick.bind(this);
                  
    }
handleChallengeTitle(event) {
        this.setState({challengeTitle: event.target.value});
    }
    handleChallengejobNumber(event) {
        this.setState({jobNumber: event.target.value});
    }
    handleChallengeBusinessAccount(event) {
        this.setState({businessAccount: event.target.value});
    }
    handleChallengePrize(event) {
        let totalCost = '0';
        if(this.state.challengeAmount){
           totalCost = (Number(this.state.challengeAmount) + Number(event.target.value)); 
        }
        this.setState({prize: event.target.value});
        this.setState({totalCost: totalCost});

    }
    handleChallengeAmount(event) {
        let totalCost = '0';
        if(this.state.prize){
           totalCost = (Number(this.state.prize) + Number(event.target.value)); 
        }
        this.setState({challengeAmount: event.target.value});
        this.setState({totalCost: totalCost});
    }
    handleStartDate(event) {
        this.setState({startDate: event.target.value});
    }
    handleEndDate(event) {
        this.setState({endDate: event.target.value});
    }
    /*handleTotalCost(event) {
        this.setState({totalCost: event.target.value});
    }*/
    
    /*handleHashtag(event) {
        this.setState({challengeHashtag: event.target.value});
    }*/
    handleCaption(event) {
        this.setState({caption: event.target.value});
    }
    toggleNotification = (postid,msg,comment) => {   console.log('toggleNotification'+postid);
      var self = this;
        self.setState(prevState => ({
          isNotificationOpen: !prevState.isNotificationOpen,
          post_Notification_Id: postid,
          post_Notification_Msg: msg,
          post_Notification_Comment:comment  
        }));
        //self.setState({ post_Notification_Id: postid });        
      };
    checkDeletePost(event)
    {
        let delete_post_id= this.challenge_id;  
        const currentUser = Parse.User.current();
        if(currentUser)
        {
            var query = new Parse.Query('Photo');  
            query.equalTo('objectId', delete_post_id);
            var self = this;
            query.find().then(function(photo){
                    if(photo)
                    {
                        let photo_ob=photo[0];
                      

                            var queryPhoto = new Parse.Query('Photo');
                            queryPhoto.equalTo("isChallengeEntry", "1");
                            queryPhoto.equalTo("challenge", "Photo$"+delete_post_id);
                            //var self = this;
                            queryPhoto.find().then(function(entries){
                            if(entries) {
                                console.log('entries');
                                console.log(entries);
                                for (const entry of entries) {
                                            console.log(entry);
                                            /*delete like*/
                                            var checklikequery = new Parse.Query('Like');  
                                            checklikequery.equalTo('photo', "Photo$"+entry.id);
                                            checklikequery.find().then((object) => {
                                                if (object) {
                                                    for (const objects of object) {
                                                          objects.destroy();
                                                    }
                                                }
                                              })
                                              .then((deletedObject) => {

                                              })
                                              .catch((error) => {
                                                console.error('Error deleting record:', error);
                                              }); 
                                              /*delete like*/

                                              /*delete activity*/
                                                var checkActivityquery = new Parse.Query('Activity');  
                                                checkActivityquery.equalTo('photo', "Photo$"+entry.id);
                                                checkActivityquery.find().then((object) => {
                                                if (object) {
                                                      for (const objects of object) {
                                                          objects.destroy();
                                                    }
                                                }
                                              })
                                              .then((deletedObject) => {

                                              })
                                              .catch((error) => {
                                                console.error('Error deleting record:', error);
                                              }); 

                                              /*delete activity*/
                                              /*delete save*/
                                              var checkSavequery = new Parse.Query('Save');  
                                              checkSavequery.equalTo('photo', "Photo$"+entry.id);
                                              checkSavequery.find().then((object) => {
                                                    if (object) {
                                                        for (const objects of object) {
                                                             objects.destroy();
                                                        }                       
                                                    }
                                                  })
                                                  .then((deletedObject) => {

                                                  })
                                                  .catch((error) => {
                                                    console.error('Error deleting record:', error);
                                                  }); 
                                                  /*delete save*/
                                                   entry.destroy();
                                    }
                            }
                        })
                         try {
                            photo_ob.destroy();

                             setTimeout(() => {
                              window.location.href = "/business/challenges";
                             },2500);
                      } catch (error) {
                        console.error('Error deleting activity:', error);
                      } 
                    }
                })
        }

    }
    handleDeleteBtn(event) {
          setTimeout(() => {
            $('#delete_with_discard').trigger('click');                 
        }, 500);       
    }
    handleStatusChange(event) {
       this.setState({challengeStatus: event.target.value});
    }    
    paymentclick(event) {
        var self = this;
        this.setState({makepayment: 1});
    }     
    checkCancelBtn(event) {
       if(this.state.makepayment===1)
       {
        window.location.href = "/payments/"+ this.challenge_id;
       }else{
        window.location.href = "/business/challenges";
       }
       //window.location.href = "/business/challenges";
    }
    challengeCancel(event) {
        //console.log('cancel button');
        //window.location.href = "/business/challenges";
        $('#challenge_cancel').trigger('click');
    }
    checkChallengeAvailable(challenge_id) {
      var query = new Parse.Query('Photo');
      query.equalTo("objectId", challenge_id);
      query.include('payment');
      var self = this;
        query.first().then(function(result){
            if(result){
                let jobNumbers=result.get('jobNumber');
                let challengeStatus=result.get('challengeStatus');
                let challengeTitle=result.get('challengeTitle');
                let barcolor=result.get('barcolor');
                let caption=result.get('caption');
                //let challengeHashtag=result.get('challengeHashtag');
                let more_content_data=result.get('more_content')?true:false;
                let more_creators_data=result.get('more_creators')?true:false;
                let image=result.get('image');
                let thumbnail=result.get('thumbnail');
                let businessAccount=result.get('businessAccount');
                let prize=result.get('prize');
                let challengeAmount=result.get('challengeAmount');
                let startDate=result.get('startDate');
                let endDate=result.get('endDate');
                let totalCost = result.get('totalCost');
                let isPaid = result.get('isPaid')?result.get('isPaid'):'';
                if(isPaid)
                {
                    let payment_total_amount=result.get('payment').get('total_amount')?result.get('payment').get('total_amount'):'';
                    let payment_created_at=result.get('payment').get('createdAt')?result.get('payment').get('createdAt'):'';
                     if(isPaid!=='')
                    {
                         self.setState({isPaid: isPaid});
                    }
                    if(payment_total_amount!=='')
                    {
                         self.setState({payment_total_amount: payment_total_amount});
                    }
                    if(payment_created_at!=='' || payment_created_at!==null)
                    {
                         self.setState({payment_created_at: payment_created_at});
                    }
                } 
                
                if(prize!=null)
                {
                     self.setState({prize: prize});
                }
                if(businessAccount!=null)
                {
                     self.setState({businessAccount: businessAccount});
                }
                if(barcolor!=null)
                {
                     self.setState({barcolorvalue: barcolor});
                }
                if(challengeAmount!=null)
                {
                    self.setState({challengeAmount: challengeAmount});
                }
                if(totalCost!=null)
                {
                    self.setState({totalCost: totalCost});
                }
                if(startDate!=null)
                {
                    self.setState({startDate: startDate});
                }
                if(endDate!=null)
                {
                    self.setState({endDate: endDate});
                }
                if(jobNumbers!=null)
                {
                   self.setState({jobNumber: jobNumbers});
                   
                }
                if(challengeTitle)
                {
                     self.setState({challengeTitle: challengeTitle});
                }
                if(challengeStatus)
                {
                     self.setState({challengeStatus: challengeStatus});
                }
                if(caption)
                {
                     self.setState({caption: caption});
                }
               /* if(challengeHashtag)
                {
                     self.setState({challengeHashtag: challengeHashtag});
                }*/

                if(more_content_data)
                {
                    
                     self.setState({more_content_valss: more_content_data});
                     if(more_content_data === true){
                        self.setState({radiotype:'2'});
                     }
                }
               

                if(more_creators_data)
                {

                   // this.setState({more_creators_vals: more_creators_data});
                   // self.setState({more_creators: more_creators_data}); 
                    self.setState({more_creators_valss: more_creators_data});
                    if(more_creators_data === true){
                        self.setState({radiotype:'1'});
                     }

                }  
                
               if(image)
               {
                 self.setState({dataurl_640_img: thumbnail.url()});
                document.getElementById("profilePicturePreview").src = image.url();   
                $("#profilePicturePreview").parent(".img_view").attr("style", "display: block");
                $('#discard_image').attr("style", "display: block");
                $(".content-wrap .file-input").attr("style", "display: none");
                $(".content-wrap .img_view").attr("style", "display: block");
                $("#dataurl_640_img").val(thumbnail.url());     
               }
               if(thumbnail)
               {
                 self.setState({dataurl_210_img:image.url()});
               }
              
             
                //console.log(result);
            }else{
                window.location.href = "/business/challenges";
            }
        })
    }

     toggleChange = () => {
      this.setState((more_creators_vals) => ({more_creators_vals: !this.state.more_creators_vals}));
  }
    checkChallengeDiscard(event) {
       $("#photoUrlInput").val('');
       $("#dataurl_210").val('');
       $("#dataurl_210_img").val('');
       $("#dataurl_640").val('');
       $("#dataurl_640_img").val('');     
       $("#challenge_title").val('');
       $("#challenge_caption").val('');
       $("#profilePicturePreview").parent(".img_view").attr("style", "display: none");
       document.getElementById("profilePicturePreview").src = "/images/defaultcamera.png";
       $(".content-wrap .file-input").attr("style", "display: block");
       $(".content-wrap .img_view").attr("style", "display: none");
       this.setState({more_creators:false});    
       this.setState({more_content:false});  
       $("#more_creators").prop("checked", false);
       $("#more_content").prop("checked", false);   
       window.history.back();      
    }
    handleMoreCreators(event) {
        //this.setState({more_creators:event.target.checked});    
    }
    handleMoreContent(event) {
        this.setState({more_content:event.target.checked});    
    }

    handleChallengeSubmit(event) {
     event.preventDefault();
     var forms = document.forms.frm_challenge;
     var barcolor='';
     var challenge_title=forms.challenge_title.value;
     var challenge_caption=forms.challenge_caption.value;
     var myRadio=$('#frm_challenge input[name="myRadio"]:checked').val();
     if(myRadio!==undefined)
     {
        barcolor=myRadio;
     }
     //var challengeHashtag=forms.challenge_hashtag.value;
     var dataurl_210=forms.dataurl_210.value;
     var dataurl_640=forms.dataurl_640.value;   
     var dataurl_210_img=forms.dataurl_210_img.value;
     var dataurl_640_img=forms.dataurl_640_img.value;
     //var more_creators_val=this.state.more_creators;
     //var more_content_val=this.state.more_content;
     var more_creators_val=this.state.more_creators_valss;
     var more_content_val=this.state.more_content_valss;
     var challengeStatus=this.state.challengeStatus;
     var jobNumber=Number(this.state.jobNumber);
     var businessAccount=this.state.businessAccount;
     var prize=this.state.prize;
     var challengeAmount = this.state.challengeAmount;
     var totalCost = this.state.totalCost;
     var startDate = this.state.startDate;
     var endDate = this.state.endDate;
     var more_creators=0;
     var more_content=0;
     var self = this;     
     var challengemainid=this.state.challengemainid;
     
     //if((dataurl_210==='' || dataurl_210_img==='') || dataurl_640_img==='')
     if((dataurl_210==='' || dataurl_210_img==='') && this.state.dataurl_640_img==='')
         {
            self.setState({challenge_image_msg:'Please upload image.'});  
         }
          if(challenge_title==='')
         {
            self.setState({challenge_title_msg:"Please write some challenge title!"}); 
         }
          if(challenge_caption==='')
         {
            self.setState({challenge_caption_msg:"Please write some challenge caption!"}); 
         }
         if((dataurl_210_img!=='' || this.state.dataurl_640_img!=='') && challenge_title!=='' && challenge_caption!=='')
         {
          if(more_creators_val == 1)
          {
            more_creators=1;
            more_content=0; 
          }
          if(more_content_val == 1)
          {
            more_content=1;
            more_creators=0;
          }

           const currentUser = Parse.User.current();
            if (currentUser) {  
                let currentUser = Parse.User.current();   
                let dataurl_640_img_valss=this.state.dataurl_640_img;
                //const photo = Parse.Object.extend('Photo');
                //const photo = new Photo(); 
                var photo = new Parse.Query('Photo');   
                photo.equalTo('objectId', this.challenge_id);

                photo.find().then(function(photosave,dataurl_640_img_valss){
                if(photosave)
                {  
                let photo=photosave[0];     //  console.log(dataurl_640_img_valss+'this.state.dataurl_640_img d');
                /*if(dataurl_640_img_valss==='')
                {
                    
                } */               
                var dataurl_210=forms.dataurl_210.value;
                var dataurl_640=forms.dataurl_640.value;  
                if(dataurl_640)
                {
                     const parseFile_210 = new Parse.File('image.jpg', { base64: dataurl_210 });
                     const parseFile_640 = new Parse.File('thumbnail.jpg', { base64: dataurl_640 });
                     photo.set('image', parseFile_640);  
                     photo.set('thumbnail', parseFile_210);
                }
                   
                //photo.set('nonMealPost', 1);
                //photo.set('prize', "");
                //photo.set('user', currentUser);
                //photo.set('isChallenge', "1");
                photo.set('caption', challenge_caption);
               // photo.set('challengeHashtag', challengeHashtag);
                photo.set('fulltext', challenge_caption);
                photo.set('challengeTitle', challenge_title);  
                photo.set('more_content', more_content);
                photo.set('more_creators', more_creators);
                photo.set('challengeStatus',challengeStatus);
               // photo.set('jobNumber',jobNumber);
                photo.set('businessAccount',businessAccount);
                photo.set('prize',prize);
                photo.set('challengeAmount',challengeAmount);
                photo.set('totalCost',totalCost);
                photo.set('startDate',startDate);
                photo.set('endDate',endDate);
                photo.set('barcolor',barcolor);
               // let updatedchallengeid=this.challenge_id;
                if(challengeStatus==="Active")
                {
                       self.toggleNotification(challengemainid,'Your challenge is approved','');
                       self.setState({isNotificationOpen:false,post_Notification_Id:'',post_Notification_Msg:'',post_Notification_Comment:''});
                       self.toggleNotification(challengemainid,'created a challenge','');
                       self.setState({isNotificationOpen:false,post_Notification_Id:'',post_Notification_Msg:'',post_Notification_Comment:''});
                }
                
                   photo.save().then((photodata) => {
                    
                      setTimeout(() => {
                           $("#photoUrlInput").val('');
                           $("#dataurl_210").val('');
                           $("#dataurl_210_img").val('');
                           $("#dataurl_640").val('');
                           $("#dataurl_640_img").val('');     
                           $("#challenge_title").val('');
                           $("#challenge_hashtag").val('');
                           $("#challenge_caption").val('');
                           $("#profilePicturePreview").parent(".img_view").attr("style", "display: none");
                           document.getElementById("profilePicturePreview").src = "/images/defaultcamera.png";
                           $(".content-wrap .file-input").attr("style", "display: block");
                           $(".content-wrap .img_view").attr("style", "display: none");
                            
                           $("#more_creators").prop("checked", false);
                           $("#more_content").prop("checked", false);
                           self.setState({challenge_message:'Challenge have been updated.'}); 
                            window.scrollTo({
                                      top: 0,
                                      left: 0,
                                      behavior: "smooth"
                                    });
                           
                      }, 500);
                     }).catch((error) => {
                    });  


                      }
               })    
            }


          
         }
         
         setTimeout(() => {
             self.setState({challenge_image_msg:null});  
             self.setState({challenge_title_msg:null}); 
             self.setState({challenge_caption_msg:null}); 
             self.setState({challenge_message:null}); 
             // window.location.href = "/business/challenges";
              if(this.state.makepayment===1)
               {
                navigator.clipboard.writeText(window.location.origin+'/payments/' + this.challenge_id);
                window.location.href = "/payments/"+ this.challenge_id;

               }else{
                window.location.href = "/business/challenges";
               }
        }, 7000);
       }
  discardImage = function(e){
     $("#photoUrlInput").val('');
     $("#dataurl_210").val('');
     $("#dataurl_210_img").val('');
     $("#dataurl_640").val('');
     $("#dataurl_640_img").val('');     
     //$("#caption").val('');
      this.setState({dataurl_640_img: ''});
     $("#profilePicturePreview").parent(".img_view").attr("style", "display: none");
     document.getElementById("profilePicturePreview").src = "/images/defaultcamera.png";
     $(".content-wrap .file-input").attr("style", "display: block");
     $(".content-wrap .img_view").attr("style", "display: none");
  }
handleFileChange = (event) => {
      
    const file = event.target.files[0];   
    var self = this;  
    if(file)
    {

    const fileSizeInBytes = file.size; 
    const fileSizeInKB = fileSizeInBytes / 1024; 
    const maxSizeInKB = 20480; // 20MB

    if (fileSizeInKB > maxSizeInKB) {
       self.setState({create_message_show:true}); 
       self.setState({create_message:"File size exceeds the limit."});        
    } else {
     const fileType = file.type;    

    if (fileType === 'image/jpeg' || fileType === 'image/png') {
                let imageFile = event.target.files[0];
                var reader = new FileReader();
                reader.onload = function (e) {
                var img = document.createElement("img");
                img.onload = function (event) {
                var custom_width_640=640;
                var custom_width_210=210;
                if(custom_width_640)
                {
                    var canvas = document.createElement("canvas");
                    const canvasResize = document.createElement('canvas');
                    const ctxResize = canvasResize.getContext('2d');                    
                    const maxWidth = 1000; 
                    const maxHeight = 1000; 
                    const aspectRatio = img.width / img.height;
                     let newHeightCheck=0;
                    let newWidthCheck=0;
                    let newMessageCheck='';

                    let newWidth = img.width;
                    let newHeight = img.height;
                    if (img.width > maxWidth) {
                        newWidth = maxWidth;
                        newHeight = newWidth / aspectRatio;
                    }
                    if (newHeight > maxHeight) {
                        newHeight = maxHeight;
                        newWidth = newHeight * aspectRatio;
                    }
                    if(custom_width_640>newHeight || custom_width_640>newWidth )
                    {
                       /* if(custom_width_640>newHeight)
                        {
                            newHeightCheck=newHeight;
                            newMessageCheck= "Please upload a larger size image. The height proportionally is "+parseInt(newHeight)+"px, it must be at least 640px";
                        }
                        if(custom_width_640>newWidth)
                        {
                            newWidthCheck=newWidth;
                             newMessageCheck= "Please upload a larger size image. The  width proportionally is "+parseInt(newWidth)+"px, it must be at least 640px";
                        }
                        if(custom_width_640>newHeight && custom_width_640>newWidth)
                        {
                            newMessageCheck= "Please upload a larger size image. The  width/height proportionally is "+parseInt(newWidth)+"px & "+parseInt(newHeight)+"px, it must be at least 640px";
                        }
                          self.setState({challenge_image_msg_size:newMessageCheck});  
                         $("#profilePicturePreview").parent(".img_view").attr("style", "display: none");
                         document.getElementById("profilePicturePreview").src = "/images/defaultcamera.png";
                         $("#photoUrlInput").val('');
                         $("#dataurl_210").val('');
                         $("#dataurl_640").val('');
                         $("#dataurl_640_img").val('');
                         $("#dataurl_210_img").val('');
                         $('#large_challenge_image_btn').trigger('click');*/

                        //new code
                        canvasResize.width = newWidth;
                        canvasResize.height = newHeight;
                        //ctxResize.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);
                        ctxResize.drawImage(img, 0, 0, img.width, img.height);
                        const resizedDataURL = canvasResize.toDataURL('image/jpeg');
                        const canvasCrop = document.createElement('canvas');
                        const ctxCrop = canvasCrop.getContext('2d');
                        const cropWidth = custom_width_640;
                        const cropHeight = custom_width_640;
                        canvasCrop.width = cropWidth;
                        canvasCrop.height = cropHeight;
                        const x = (newWidth - cropWidth) / 2;
                        const y = (newHeight - cropHeight) / 2;
                        //ctxCrop.drawImage(canvasResize, x, y, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
                        ctxCrop.drawImage(canvasResize, 0, 0, cropWidth, cropHeight);
                        const croppedDataURL = canvasCrop.toDataURL('image/jpeg');
                        var dataurl_640 = croppedDataURL; 
                        
                        //new code
                    }else
                    {

                    canvasResize.width = newWidth;
                    canvasResize.height = newHeight;
                    ctxResize.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);
                    const resizedDataURL = canvasResize.toDataURL('image/jpeg');
                    const canvasCrop = document.createElement('canvas');
                    const ctxCrop = canvasCrop.getContext('2d');
                    const cropWidth = custom_width_640;
                    const cropHeight = custom_width_640;
                    canvasCrop.width = cropWidth;
                    canvasCrop.height = cropHeight;
                    const x = (newWidth - cropWidth) / 2;
                    const y = (newHeight - cropHeight) / 2;
                    ctxCrop.drawImage(canvasResize, x, y, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
                    const croppedDataURL = canvasCrop.toDataURL('image/jpeg');
                    var dataurl_640 = croppedDataURL; 

                }

                    //new
                     if(custom_width_210)
                {
                    var canvas = document.createElement("canvas");                    
                    const canvasResize = document.createElement('canvas');
                    const ctxResize = canvasResize.getContext('2d');                    
                    const maxWidth = 500; 
                    const maxHeight = 500; 
                    const aspectRatio = img.width / img.height;
                    let newWidth = img.width;
                    let newHeight = img.height;

                    if (img.width > maxWidth) {
                        newWidth = maxWidth;
                        newHeight = newWidth / aspectRatio;
                    }
                    if (newHeight > maxHeight) {
                        newHeight = maxHeight;
                        newWidth = newHeight * aspectRatio;
                    }

                   
                    /*commented */
                    /*canvasResize.width = newWidth;
                    canvasResize.height = newHeight;
                    ctxResize.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);

                    const resizedDataURL = canvasResize.toDataURL('image/jpeg');

                    const canvasCrop = document.createElement('canvas');
                    const ctxCrop = canvasCrop.getContext('2d');
                    const cropWidth = custom_width_210;
                    const cropHeight = custom_width_210;
                    canvasCrop.width = cropWidth;
                    canvasCrop.height = cropHeight;
                    const x = (newWidth - cropWidth) / 2;
                    const y = (newHeight - cropHeight) / 2;
                    ctxCrop.drawImage(canvasResize, x, y, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
                    const croppedDataURL = canvasCrop.toDataURL('image/jpeg');
                    var dataurl_210 = croppedDataURL;*/
                    /*commented */
                    /*new code*/

                    /*new code 1*/
                    if(custom_width_210>newHeight || custom_width_210>newWidth )
                    {

                        canvasResize.width = newWidth;
                        canvasResize.height = newHeight;
                        //ctxResize.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);
                        ctxResize.drawImage(img, 0, 0, img.width, img.height);
                        const resizedDataURL = canvasResize.toDataURL('image/jpeg');
                        const canvasCrop = document.createElement('canvas');
                        const ctxCrop = canvasCrop.getContext('2d');
                        const cropWidth = custom_width_210;
                        const cropHeight = custom_width_210;
                        canvasCrop.width = cropWidth;
                        canvasCrop.height = cropHeight;
                        const x = (newWidth - cropWidth) / 2;
                        const y = (newHeight - cropHeight) / 2;
                        //ctxCrop.drawImage(canvasResize, x, y, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
                        ctxCrop.drawImage(canvasResize, 0, 0, cropWidth, cropHeight);
                        const croppedDataURL = canvasCrop.toDataURL('image/jpeg');
                        var dataurl_210 = croppedDataURL; 
                    }else
                    {
                        canvasResize.width = newWidth;
                        canvasResize.height = newHeight;
                        ctxResize.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);
                        const resizedDataURL = canvasResize.toDataURL('image/jpeg');
                        const canvasCrop = document.createElement('canvas');
                        const ctxCrop = canvasCrop.getContext('2d');
                        const cropWidth = custom_width_210;
                        const cropHeight = custom_width_210;
                        canvasCrop.width = cropWidth;
                        canvasCrop.height = cropHeight;
                        const x = (newWidth - cropWidth) / 2;
                        const y = (newHeight - cropHeight) / 2;
                        ctxCrop.drawImage(canvasResize, x, y, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
                        const croppedDataURL = canvasCrop.toDataURL('image/jpeg');
                        var dataurl_210 = croppedDataURL;
                    }
                    /*new code 1*/ 

                   
                  
                }
                if(dataurl_640!==undefined || dataurl_210!==undefined)
                {
                const dataurl_210s = document.getElementById('dataurl_210');
                dataurl_210s.value = dataurl_210;
                const dataurl_640s = document.getElementById('dataurl_640');
                dataurl_640s.value = dataurl_640;
                
                const parseFile_210 = new Parse.File('image.jpg', { base64: dataurl_210 });
                const parseFile_640 = new Parse.File('thumbnail.jpg', { base64: dataurl_640 });

                parseFile_640.save().then((savedFile) => {
                 const dataurl_640_img = document.getElementById('dataurl_640_img');
                 dataurl_640_img.value = savedFile.url();
                }).catch((error) => {
                  console.error('Error saving file:', error);
                });
                parseFile_210.save().then((savedFile) => {
                 const dataurl_210_img = document.getElementById('dataurl_210_img');
                 dataurl_210_img.value = savedFile.url();
                }).catch((error) => {
                  console.error('Error saving file:', error);
                });
                document.getElementById("profilePicturePreview").src = dataurl_640;   
                $("#profilePicturePreview").parent(".img_view").attr("style", "display: block");
                $('#discard_image').attr("style", "display: block");
                $(".content-wrap .file-input").attr("style", "display: none");
                $(".content-wrap .img_view").attr("style", "display: block");
              }


                    //new
                  // } commented
                }
               
                    
            }
                    img.src = e.target.result;
                }
                reader.readAsDataURL(imageFile);
            }else {
               self.setState({create_message_show:true});  
               self.setState({create_message:'Unsupported file type.'}); 
              // You can display an error message or take other actions here
            }
          }
          setTimeout(() => {
          self.setState({create_message_show:false});  
          self.setState({create_message:null}); 
          self.setState({photo_image_message:null});     
          self.setState({challenge_image_msg:null});  
                
        }, 3000);
     }
  


};
onSiteChanged = (e) => {
    if(e.currentTarget.value == 1){
        this.setState({radiotype: '1'});
        this.setState({more_creators_valss: 1});
        this.setState({more_content_valss: 0});
    }
    if(e.currentTarget.value == 2){
        this.setState({radiotype: '2'});
        this.setState({more_creators_valss: 0});
        this.setState({more_content_valss: 1});
    }
};
  render() {
    let jobNumber=this.state.jobNumber;
    let more_creators_value=this.state.more_creators_vals;
    let businessAccount=this.state.businessAccount;
    let prize=this.state.prize;
    let challengeAmount = this.state.challengeAmount;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let totalCost = this.state.totalCost;
    let radio = this.state.radiotype;
    let content_val = this.state.more_content_valss;
    let creator_val = this.state.more_creators_valss;
    let barcolorvalue=this.state.barcolorvalue;
    var isNotificationOpen=this.state.isNotificationOpen; 
    var post_Notification_Ids=this.state.post_Notification_Id; 
    var post_Notification_Msg=this.state.post_Notification_Msg; 
    var post_Notification_Comment=this.state.post_Notification_Comment;

    return ( 
      <React.Fragment>{isNotificationOpen?<SaveNotification   isOpen={isNotificationOpen}  postNotificationid={post_Notification_Ids} postMsg={post_Notification_Msg} postPhotoid={post_Notification_Ids} postActivityComment={post_Notification_Comment}  />:""}  
        <div className='createChallange__mn editChallange__mn'>
          <form className="frm_challenge"  id="frm_challenge" onSubmit={this.handleChallengeSubmit}>
            <div className='top-part_inner'>
              <div className='wrapper'>
                <div className='top-part'>
               
                    <div className="adminTop_account_main">    
                     <p className='create_message_alert'  style={{"color":"#3c8643"}}>{this.state.challenge_message}</p>                    
                        <div className="row">
                            <div className="col-md-2 col-sm-2 padd_right_zero">
                                <h3 className="admin_title">Admin</h3>
                                <div className="list_admin">
                                    <label className='left'>Job Number:</label>
                                    <div className="admin-input">
                                       {/* <input type="number" name="number"  class="number_input" placeholder={jobNumber}  />*/}
                                         <input type="number" name="job_num"  readOnly={true}  value={jobNumber} onChange={this.handleChallengejobNumber}  class="number_input" id="job_num" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 padd_all_zero">
                                <h3 className="admin_title empty-spaceing">&nbsp;</h3>
                                <div className="list_admin">
                                    <label className='left'>Business Account:</label>
                                    <div className="admin-input">
                                    <input type="text"  value={businessAccount} onChange={this.handleChallengeBusinessAccount}   name="busn_acnt" class="frm-cntrl" id="busn_acnt" />
                                     
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 padd_left_zero">
                                <div className="row">
                                    <div className="col-md-7 col-sm-7 padd_all_zero">
                                        <h3 className="admin_title empty-spaceing">&nbsp;</h3>
                                        <div className="list_admin">
                                            <label className='left'>Job Status:</label>
                                            <div className="admin-input icon_down_arrow_position">
                                                <div className="icon_down_arrow"><img src="/images/Chevron.svg" alt="Chevron" /></div>
                                                <select  className='badges pending'
                                                value={this.state.challengeStatus} 
                                                onChange={this.handleStatusChange}
                                              > 
                                                {options.map(option => ( 
                                                  <option key={option.value} value={option.value}> 
                                                    {option.label} 
                                                  </option> 
                                                ))} 
                                              </select> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-sm-5 padd_left_25">
                                        <h3 className="admin_title empty-spaceing">&nbsp;</h3>
                                        <div className="list_admin">
                                            <label className='left'>Bar Color:</label>
                                             <div className="radio_checkbar">
                                                <form className="radio_inline">
                                                  
   {/* <div className="myradio">
        <input type="radio" name="myRadio" id="one" className="myradio__input" />
        <label for="one" class="red myradio__label"></label>
    </div>
    <div className="myradio">
        <input type="radio" name="myRadio" id="two" className="myradio__input" />
        <label for="two" class="green myradio__label"></label>
    </div>
    <div className="myradio">
        <input type="radio" name="myRadio" id="three" className="myradio__input" />
        <label for="three" class="blue myradio__label"></label>
    </div>
    <div className="myradio">
        <input type="radio" name="myRadio" id="four" className="myradio__input" />
        <label for="four" class="purple myradio__label"></label>
    </div>*/}
                                                
                                                 <div className="myradio">
         {barcolorvalue=='C13828'?<input type="radio" name="myRadio" id="red" value="C13828" checked className="myradio__input" />:<input type="radio" name="myRadio" id="red" value="C13828" className="myradio__input" />} 
        <label for="red" class="red myradio__label"></label>
    </div>
    <div className="myradio">
     {barcolorvalue=='346D39'?<input type="radio" checked name="myRadio" id="green"  value="346D39"  className="myradio__input" />:<input type="radio" name="myRadio" id="green"  value="346D39"  className="myradio__input" />} 
        
        <label for="green" class="green myradio__label"></label>
    </div>
    <div className="myradio">
     {barcolorvalue=='0D1B9A'?<input type="radio" checked name="myRadio" id="blue" value="0D1B9A"  className="myradio__input" />:<input type="radio" name="myRadio" id="blue" value="0D1B9A"  className="myradio__input" />}  
        
        <label for="blue" class="blue myradio__label"></label>
    </div>
    <div className="myradio">
     {barcolorvalue=='62007B'?  <input type="radio" checked name="myRadio" id="purple"  value="62007B"  className="myradio__input" />:  <input type="radio" name="myRadio" id="purple"  value="62007B"  className="myradio__input" />} 
      
        <label for="purple" class="purple myradio__label"></label>
    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="row">
                            <div className="col-md-7">
                                <h3 className="admin_title">Budget</h3>
                                <div className="budget_listing">
                                    <ul className="budget_ul">
                                        <li className="budget_li_col"> 
                                            <div className="list_admin">
                                                <label className='left'>Challenge Amount:</label>
                                                <div className="admin-input">
                                                    
                                                    <input type="number" name="number"  class="account_input doller-icon" required placeholder=""  onChange={this.handleChallengeAmount} value={challengeAmount}/>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="plus_li"><span className="plus_icon">+</span></li>
                                        <li className="budget_li_col"> 
                                            <div className="list_admin">
                                                <label className='left'>Prize Amount:</label>
                                                <div className="admin-input">
                                                
                                                    <input type="number" name="number"  class="account_input doller-icon" required placeholder="" onChange={this.handleChallengePrize} value={prize} />
                                                </div>
                                            </div>
                                        </li>
                                        <li className="plus_li"><span className="equal_icon plus_icon">=</span></li>
                                        <li className="budget_li_col"> 
                                            <div className="list_admin">
                                                <label className='left'>Total Cost</label>
                                                <div className="admin-input">

                                                    <input type="number" name="number" required class="account_input doller-icon" placeholder=""  value={totalCost} />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>                                
                            </div>
                            <div className="col-md-5 margin_top_30 padd_left_25">
                                <h3 className="admin_title">Schedule</h3>
                                <div className="row">
                                    <div className="col-md-6 col-sm-6">
                                        <div className="list_admin">
                                            <label className='left'>Start Date:</label>
                                            <div className="admin-input date_input">
                                                <input type="date" name="number"  class="account_input" onChange={this.handleStartDate} value={startDate}  required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="list_admin">
                                            <label className='left'>End Date:</label>
                                            <div className="admin-input">
                                                <input type="date" name="number"  class="account_input" onChange={this.handleEndDate} value={endDate}  required  />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="payment_link">{/*<a href={'/makepayment/' + this.challenge_id}>Save and copy payment link <img src="/images/link.svg" alt="link" /></a>*/}
                        {/* onClick={this.paymentclick}*/}
                        {this.state.isPaid?<h3 class="admin_title">Paid ${this.state.payment_total_amount} on {this.state.payment_created_at != undefined ?((this.state.payment_created_at.getMonth()+1) + "/" + (this.state.payment_created_at.getDate()) + "/" + this.state.payment_created_at.getFullYear().toString().substr(-2)):''}</h3> :


                         <div class="cta_btn third-btn"><button onClick={() => this.paymentclick()}   class="btn-submt">Save and copy payment link <img src="/images/link.svg" alt="link" /> </button></div>
                     }

                        </div>
                    </div>
                  {/*<div className='content-main'>
                    <aside className='box job_num_grp'>
                      <label className='left'>Job Number: </label>
                      <span className='fld'>
                        <input type="number" name="job_num" value={jobNumber} onChange={this.handleChallengejobNumber}  class="frm-cntrl" id="job_num" />
                      </span>
                    </aside>
                    <aside className='box busn_acnt_grp'>
                      <label className='left'>Business Account: </label>
                      <span className='fld'><input type="text"  value={businessAccount} onChange={this.handleChallengeBusinessAccount}   name="busn_acnt" class="frm-cntrl" id="busn_acnt" /></span>
                    </aside>
                    <aside className='box prz_amunt_grp'>
                      <label className='left'>Prize Amount:</label>
                      <span className='fld'><input type="number"  value={prize} onChange={this.handleChallengePrize}   name="prz_amunt" class="frm-cntrl" id="prz_amunt" /></span>
                    </aside>
                    <aside className='box job_stat_grp'>
                      <label className='left'>Job Status: </label>
                      <span className='fld'>
                        <select  className='badges pending'
                        value={this.state.challengeStatus} 
                        onChange={this.handleStatusChange}
                      > 
                        {options.map(option => ( 
                          <option key={option.value} value={option.value}> 
                            {option.label} 
                          </option> 
                        ))} 
                      </select> 
                      </span>
                    </aside>
                  </div>*/}
                </div>
              </div>
            </div>
            <div className='bottom-part_inner'>
              <div className='wrapper'>
                <div className='bottom-part'>
                  <div className='inner_wrap'>
                    <aside className='card-wrap preview-challenge'>
                      <div className='title-wrap'>
                        <h3>Challenge Photo</h3>
                      </div>
                      <div className='content-wrap'>
                        <div className='file_upld'>
                          <div className='img_view' style={{display:"none"}}>
                            <button type="button" style={{display:"none"}} onClick={this.discardImage}  id="discard_image"  className='edit_icon' >
                              <img src="/images/img_edit.png" alt="Photo edit" />
                            </button>
                            <img height="" width="250" id="profilePicturePreview" src="/images/defaultcamera.png" alt="photo Picture" />
                          </div>
                          <div className='file-input'>
                            <label htmlFor="myFile">                       
                              <input type="hidden" id="photoUrlInput" name="photoUrl"/>
                              <input type="hidden" id="dataurl_210" name="dataurl_210"/>
                              <input type="hidden" id="dataurl_640" name="dataurl_640"/>
                              <input type="hidden" id="dataurl_640_img" name="dataurl_640_img"/>
                              <input type="hidden" id="dataurl_210_img" name="dataurl_210_img"/>
                              <img src="/images/file_upload.svg" alt="Joel's Kitchen"/>
                              <span>Choose a file to upload.</span>
                            </label>
                            <input type="file" id="myFile"   onChange={this.handleFileChange}    accept=".jpg, .jpeg, .png"  name="filename"  />
                            <div className='file-infor'>
                              <label>We recommend using high quality .jpg & .png files less than 20MB.</label> 
                            </div>
                          </div>
                        </div>
                        <p className='create_message_alert'>{this.state.challenge_image_msg}{this.state.create_message}</p>
                      </div>
                    </aside>
                    <aside className='card-wrap  create-challenge'>
                      <div className='title-wrap'>
                        <h3>Edit Challenge <a href='javascript:void(0);' type="button" data-toggle="modal" data-target="#createChallengeModal"><img src="/images/info-icon.svg" alt="Info Icon"/></a></h3>
                      </div>
                      <div className='content-wrap frm-wrapper'>
                        <div className='frm-grp'>
                          <label className='frm-lbl'>Title </label>
                        
                          <input type="text" placeholder='Give your challenge a title.' onChange={this.handleChallengeTitle}  autocomplete="off"  value={this.state.challengeTitle}  name="challenge_title" className="frm-cntrl" id="challenge_title" />
                          <p className='create_message_alert'>{this.state.challenge_title_msg}</p>
                        </div>
                        {/*<div className='frm-grp'>
                          <label className='frm-lbl'>Hashtag</label>
                          <input type="text" placeholder='Add a hashtag to give your challenge a grand kick off.'  autocomplete="off"  value={this.state.challengeHashtag} onChange={this.handleHashtag}   name="challenge_hashtag" className="frm-cntrl" id="challenge_hashtag" />
                          <p className='create_message_alert'>{this.state.challenge_title_msg}</p>
                        </div>*/}
                        <div className='frm-grp'>
                          <label className='frm-lbl'>Creative Direction</label>
                          <textarea placeholder='Create a fun and simple description to guide creators.' name="challenge_caption"  autocomplete="off"  className="frm-cntrl" id="challenge_caption"  onChange={this.handleCaption}  value={this.state.caption}    value={this.state.caption? this.state.caption : "" }  />
                          <p className='create_message_alert'>{this.state.challenge_caption_msg}</p>
                        </div>
                      </div>
                    </aside>
                    <aside className='card-wrap goal_challenge'>
                      <div className='title-wrap'>
                        <h3>What is your goal? <a href='javascript:void(0);' type="button" data-toggle="modal" data-target="#challengeGoalsModal"><img src="/images/info-icon.svg" alt="Info Icon"/></a></h3>
                        <p>Choose a goal for your challenge.</p>
                      </div>
                      <div className='content-wrap frm-wrapper'>
                        <ul>
                          <li>
                          {/*  <input type="radio" id="more_creators" name="goal_challenge" value="More Creators" />*/}
                          {/*<input className="form-check-input" type="radio"   value="1" name="more_creators_grp" id="more_creators" checked={radio === 'creator'} checked={this.state.more_creators_valss}   onChange={()=>this.setState({more_creators_valss:!this.state.more_creators_valss})}  />  */}
                          {/* onChange ={this.handleMoreCreators}    <label className="form-check-label" for="more_creators">*/}
                          <input className="form-check-input" type="radio"   name="more_creators_grp" id="more_creators" value='1' checked={radio === '1'} onChange={this.onSiteChanged}  />  
                            <label for="more_creators">
                              <h6>More Creators</h6>
                              <p>Get more creators for your brand</p>
                            </label>
                          </li>
                          <li> 
                        {/*   <input type="radio" id="more_content" name="goal_challenge" value="More content" />*/}
                            {/*<input className="form-check-inputs" type="radio" value="1" name="more_creators_grp" id="more_content" checked={radio === 'content'}   checked={this.state.more_content_valss}    onChange={()=>this.setState({more_creators_valss:!this.state.more_content_valss})}  />*/}
                          <input className="form-check-input" type="radio"   name="more_creators_grp" id="more_content" value='2' checked={radio === '2'} onChange={this.onSiteChanged}  />  
                            {/*<input className="form-check-inputs" type="radio" value="1" name="more_creators" id="more_content"   checked={this.state.more_content_valss}    onClick={()=>this.setState({more_content_valss:!this.state.more_content_valss})}  />*/}
                            {/*  defaultChecked={true}  onChange ={this.handleMoreContent}*/}
                            <label for="more_content">
                              <h6>More Content</h6>
                              <p>Get more content for your brand</p>                          
                            </label>
                          </li>
                        </ul>  
                      </div>
                    </aside>
                    <div className="action_grp btn-group">
                      <div class="cta_btn fourth-btn">
                        <button type="button" className='btn-canl'   onClick={this.checkCancelBtn}  >Cancel</button>
                        {/*onClick={this.challengeCancel} */}
                      </div>
                      <div class="cta_btn  third-btn danger-btn">
                          <button  type="button"  className=''  onClick={this.handleDeleteBtn} >Delete</button>

                      </div>
                      {/* onClick={this.handleDelete}*/}
                      <div class="cta_btn third-btn">
                          <button className='btn-submt'>Save </button>
                      </div>
                    </div>
                    <div className="chalng_info text-center">
                      <p>Challenges are reviewed within 24 hours. <br />By creating a challenge you agree to the <a href='javascript:void(0)'>Challenge Rules.</a></p>
                    </div>
                  </div>               
                </div>
              </div>
            </div>
          </form>
        </div>
        {/*popup*/}

        <button href='javascript:void(0);' id="challenge_update_submitted" type="button" data-toggle="modal" data-target="#chnlgPostupdateConfrModal"  style={{display:"none"}} >submit</button>
        <div className="modal fade post_modal post_modal_overlay createPostCloseModal chnlgPostupdateConfrModal" id="chnlgPostupdateConfrModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="createPostCloseTitle" aria-hidden="true">
          <div className='bg_overlay'></div>
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className='inner text-center'>
                  <div className='title-main'>
                    <h6>Thank you for submitting your challenge!</h6>
                    <p>Joel’s Kitchen will contact you within 24 hours to discuss budget and payment. </p>
                  </div>
                  <div className='action-part'>
                    <a href='javascript:void(0);' className='btn cancel  btn-post-cancel'  onClick={this.checkCancelBtn} data-dismiss="modal" aria-label="Close" title='Cancel'>Close</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button href='javascript:void(0);' id="challenge_cancel" type="button" data-toggle="modal" data-target="#challengCloseModal"  style={{display:"none"}}>submit</button>
        <div className="modal fade post_modal post_modal_overlay createPostCloseModal" id="challengCloseModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="createPostCloseTitle" aria-hidden="true">
          <div className='bg_overlay'></div>
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className='inner text-center'>
                  <div className='title-main'>
                    <h6>Are you sure you want to leave?</h6>
                    <p>If you exit, your challenge won’t be saved.</p>
                  </div>
                  <div className='action-part'>
                    <a href='javascript:void(0);' className='btn danger btn-post-discard' data-dismiss="modal" aria-label="Close" title='Discard'  onClick={this.checkChallengeDiscard}>Discard</a>
                    <a href='javascript:void(0);' className='btn cancel  btn-post-cancel'  onClick={this.checkCancel} data-dismiss="modal" aria-label="Close" title='Cancel'>Cancel</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button href='javascript:void(0);' id="large_challenge_image_btn" type="button" data-toggle="modal" data-target="#large_challenge_image"  style={{display:"none"}}>submit</button>
        <div className="modal fade post_modal post_modal_overlay createPostCloseModal large_challenge_image" id="large_challenge_image" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="createPostCloseTitle" aria-hidden="true">
          <div className='bg_overlay'></div>
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className='inner text-center'>
                  <div className='title-main'>
                    <p>{/*Please upload a larger size image!*/}{this.state.challenge_image_msg_size}</p>
                    
                  </div>
                  <div className='action-part'>
                    <a href='javascript:void(0);' className='btn cancel  btn-post-cancel' data-dismiss="modal" aria-label="Close" title='Close'>Close</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         {/*<a  href='javascript:void(0);'  className="dropdown-item danger" type="button" id="delete_with_discard"    data-toggle="modal" data-target="#deletePostCloseModal"   >Delete</a>*/}
          <button href='javascript:void(0);' id="delete_with_discard" type="button" data-toggle="modal"  data-target="#deletePostCloseModal"   style={{display:"none"}}>submit</button>
         <div className="modal fade post_modal post_modal_overlay deletePostCloseModal" id="deletePostCloseModal" data-backdrop="false" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="createPostCloseTitle" aria-hidden="true">
          <div className='bg_overlay'></div>
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className='inner text-center'>
                  <div className='title-main'>
                    <h6>Are you sure you want to delete your challenge?</h6>
                    <p>This cannot be undone.</p>
                  </div>
                  <div className='action-part'>
                    <input type="hidden" id="delete_post_id" name="delete_post_id" />
                    <a href='javascript:void(0);' className='btn danger btn-post-discard' data-dismiss="modal" aria-label="Close"  onClick={(e) => this.checkDeletePost()} title='Discard'>Delete</a>
                    <a href='javascript:void(0);' className='btn cancel  btn-post-cancel' data-dismiss="modal" aria-label="Close" title='Cancel'>Cancel</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*popup*/}{/*onClick={(e) => this.deletePostId(this.state.postID)} */}
      </React.Fragment>
    )
  }
}

export default EditChallenge;
